// COLORS
$white: #F2F4F5;
$sky_white: #FFFFFF;
$sky_white_transparent: #ffffffd7;
$sky_white_lighter: #F2F4F5;
$black: #202325;
$black_light: #6C7072;
$shadow: #595d60;

$primary_color: #5538EE;
$primary_color_base: #6B4EFF;
$primary_color_light: #9990FF;
$primary_color_lighter: #C6C4FF;
$primary_color_lightest: #E7E7FF;

$red_dark: #D3180C;
$red_base: #FF5247;
$red_lighter: #FF9898;

$green_dark: #198155;
$green_base: #23C16B;
$green_lighter: #7DDE86;

$light_grey: #E3E5E6;
$light_grey_transparent: #e3e5e686;

// FONT SIZES
$fs_xs: 0.6rem;
$fs_sm: 0.8rem;
$fs_md: 1rem;
$fs_lg: 1.8rem;
$fs_xl: 2.6rem;
$fs_xxl: 3.6rem;
$fs_xxxl: 7.2rem;

/****** ICON SIZE  ******/
$icon-x-sm: 10px;
$icon-sm: 14px;
$icon-large: 20px;
$icon-x-large: 24px;