@import "../../../assets/scss/variables.scss";

.modal {
    background-color: $light_grey_transparent;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    max-width: 600px;
    min-width: 375px;
    padding: 50px 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    background-color: $white;
    border-radius: 25px;
    box-shadow: 5px 5px 10px $light_grey;

    h2 {
        font-size: $fs_lg;
        font-weight: 300;
        color: $black;
    }
}

.actions__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}


