@import "../../../assets/scss/variables.scss";
.title {
    align-self: flex-start;
    margin-bottom: 20px;

    h1{
        font-size: $fs_xl;
        font-weight: 300;
        color: $black;
    }
}