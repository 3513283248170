@import "../../../assets/scss/variables";

  .title__container {
    width: 95%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }

  .filter_btn__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
  }

  .table__container {
    border-collapse: collapse;
    width: 95%;
    overflow-x: scroll;
    box-shadow: 6px 6px 8px $light_grey;
  }
  
  .table__headers {
    border-right: 2px solid $light_grey_transparent;
    text-align: left;
    padding: 15px;
    background-color: $primary_color_base;
    color: $white;
    font-weight: 600;
    font-size: $fs_md;  
  }

  .table__headers:first-child {
    border-radius: 12px 0 0 0;
  }

  .table__headers:last-child {
    border:none;
    border-radius: 0 12px 0 0;
  }

  .table__cells {
    border: 2px solid $light_grey_transparent;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    color: $black;
    font-weight: 300;
    font-size: $fs_md;
  }
  
  tr:nth-child(odd) {
    background-color: $light_grey;
  }
  tr:nth-child(even) {
    background-color: $white;
  }

  .button__wrapper {
    width: 95%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .table_actions__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .action__link {
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: $fs_md;
    padding: 4px 8px;
    text-decoration: none;
    color: $black_light;
    border-radius: 15px;
    cursor: pointer;
    transition-duration: 0.3s;
  }

  .action__link:hover {
    background-color: $black_light;
    border-radius: 15px;
    color: $white;
    box-shadow: 2px 2px 3px $shadow;
    transition-duration: 0.3s;
  }

  .action__link:last-child {
    color: $red_base;
  }

  .action__link:last-child:hover {
    background-color: $red_base;
    box-shadow: 2px 2px 3px $red_dark;
    color: $white;
  }

  .pagination__input {
    width: fit-content;
    background-color: $light_grey_transparent;
    color: $black_light;
    height: 35px;
    border-radius: 10px;
    border:none;
    text-align: center;
    padding: 5px 5px;
    font-weight: 600;
    box-shadow: 2px 2px 5px $light_grey;
  }