@import "../../assets/scss/variables.scss";

.icon_img__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.upload_icon {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: auto;
    top: -8px;
    left: 85px;
    color: $primary_color_base;
    background-color: $sky_white_transparent;
    border: 1px solid $primary_color_base;
    padding: 5px 5px;
    border-radius: 50%;
    z-index: 2;
}

.edit__image {
  cursor: pointer;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition-duration: 0.3s;
}

.edit__image:hover {
    opacity: 0.6;
    transition-duration: 0.3s;
}