@import "../../../assets/scss/variables.scss";

.wrapperClassName{
    min-width: 50vw;
    max-height: 60vh;
    overflow-y: scroll;
    background-color: $sky_white;
    word-wrap: break-word;
    border-radius: 0 0 15px 15px;
}

.editorClassName {
    max-width: 800px;
    min-height: 400px;
    color: $black;
    padding: 0px 20px 50px 20px;
    word-wrap: break-word;
    border-radius: 0 0 15px 15px;
}

.edit_content_btn__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}