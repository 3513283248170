@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './reset';
@import './variables';
@import './scrollbar';
@import './typography';
@import './global.scss';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 10vh;
  background-color: $white;
  font-family: 'Inter', sans-serif;
}

body {
  position: relative;
  
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  img,
  svg {
    pointer-events: none;
  }
}

::placeholder {
  font-size: 12px;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
  padding: 0;
  margin: 0;
}

img,
picture,
video,
canvas,
svg {
  max-width: 100%;
}

a[role='link'] {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
