@import "../../assets/scss/variables.scss";


.edit__container {
    min-height: calc(100vh - 70px);
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit__wrapper {
    max-width: 700px;
}

.edit_header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.edit_header__image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.edit__form {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
}

.divider {
    width: 100%;
    margin-top: 30px;
}

.edit_btn__wrapper {
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
}

.switch__wrapper {
    padding-left: 20px;
    display: flex;
    gap: 80px;
}