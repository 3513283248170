@import "../../assets/scss/variables.scss";

.forgot-password__container {
    min-height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.input_btn__wrapper_email {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.input_btn__wrapper_code {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
}

.btn_code__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.authcode__container {
    display: flex;
    gap: 15px;
}

.authcode__input {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    border: none;
    border: 1px solid $black;
}

.input_message__wrapper {
display: flex;
flex-direction: column;
gap: 20px;
    p {
    margin-top: -5px;
    }
}

.forgot-password__message {
    align-self: center;
    margin-top: 10px;
    font-size: $fs_sm;
    color: $black;
}
