//we can use this file to set the typography

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
   Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
