/*
		Position
*/
.position-relative {
  position: relative;
}

/*
		Flex
*/
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-inline-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/*
		Pointer
*/
.no-pointer {
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
  pointer-events: auto;
}

/*
		Text
*/
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/*
		width and height
*/
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.h-100 {
  height: 100%;
}

/*
		visibility
*/
.hidden {
  width: 100%;
  height: 100%;

  visibility: hidden;
}
.visibility-hidden {
  visibility: hidden;
}
/*
		scroll overflow
*/
.no-scroll {
  overflow: hidden;
}
