@import "../../../assets/scss/variables.scss";

.loader__wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: $sky_white_transparent;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
