.hideScrollbar {
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // Internet Explorer 10+

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(229, 229, 229, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  -ms-overflow-style: initial; /* IE and Edge */
  scrollbar-width: initial;
}
