@import "../../../assets/scss/variables.scss";

.card__container {
    max-width: 600px;

    h2 {
        font-size: $fs_lg;
        font-weight: 300;
    }
}

.card__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
    max-width: 100%;
}

.profile_image {
    max-width: 200px;
}

.card_info__wrapper {
    font-weight: 300;
    max-width: 300px;
    
    li {
        margin-top: 10px;
    }

    span {
        font-weight: 600;
    }

    p {
        font-size: $fs_md;
    }
}

.btn__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}