@import "../../../assets/scss/variables";

.btn__primary {
    min-width: 200px;
    background-color: $primary_color_base;
    color: $white;
    border: none;
    border-radius: 25px;
    padding: 10px 40px;
    font-size: $fs_md;
    transition-duration: 0.3s;
}

.btn__primary:hover {
    transition-duration: 0.3s;
    background-color: $primary_color_light;
}

.btn__secondary {
    min-width: 200px;
    background-color: $black;
    color: $white;
    border: none;
    border-radius: 25px;
    padding: 10px 40px;
    font-size: $fs_md;
    transition-duration: 0.3s;
}

.btn__secondary:hover {
    background-color: $black_light;
    transition-duration: 0.3s;
}

.btn__danger {
    min-width: 200px;
    background-color: $red_dark;
    color: $white;
    border: none;
    border-radius: 25px;
    padding: 10px 40px;
    font-size: $fs_md;
    transition-duration: 0.3s;
}

.btn__danger:hover {
    background-color: $red_lighter;
    transition-duration: 0.3s;
}

.btn__save {
    min-width: 200px;
    background-color: $green_base;
    color: $white;
    border: none;
    border-radius: 25px;
    padding: 10px 40px;
    font-size: $fs_md;
    transition-duration: 0.3s;
}

.btn__save:hover {
    background-color: $green_lighter;
    transition-duration: 0.3s;
}

.btn__add {
    margin-top: 4px;
    padding-bottom: 5px;
    padding-left: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: $green_base;
    color: $white;
    border: none;
    border-radius: 50%;
    font-size: $fs_xl;
    box-shadow: 3px 3px 5px $light_grey;
    transition-duration: 0.3s;
}

.btn__add:hover {
    background-color: $green_lighter;
    transition-duration: 0.3s;
}

button:disabled {
    opacity: 0.2;
    cursor: not-allowed;
}