@import "../../assets/scss/variables.scss";

.login_section__container {
    min-height: calc(100vh - 70px);
}

.login__container {
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.logo__large {
    padding: 25px 10px;
}

.login_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.forgot_password {
    font-size: $fs_sm;
    margin-top: 10px;
    border: 1px solid $black;
    color: $black;
    border-radius: 25px;
    padding: 12px 28px;
    transition-duration: 0.3s;
}

.forgot_password:hover {
    background-color: $black;
    color: $white;
    cursor: pointer;
    transition-duration: 0.3s;
}

.forgot_password__link {
    text-decoration: none;
}