@import "../../assets/scss/variables.scss";

.page_not_found__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 50px;

 h1 {
    color: $black;
    font-size: $fs_lg;
    font-weight: 300;
 }
}