@import "../../assets/scss/variables.scss";

.profile__container {
    min-height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.profile__wrapper {
    max-width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.title__wrapper {
    margin-top: 40px;
    width: 85%;
}

.details__wrapper {
    width: 83%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.details__input_wrapper {
    width: 83%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.profile__divider {
    width: 90%;
    margin: 40px 0px;

}

.subtitle__wrapper {
    width: 85%;

    h2 {
        font-size: $fs_lg;
        color: $primary_color_base;
        font-weight: 300;
    }

    em {
        font-size: $fs_sm;
        font-weight: 300;
        color: $black_light;
    }
}

.profile_form__wrapper {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.profile_btn__wrapper {
    width: 50%;
    margin-top: 60px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}