@import "../../../assets/scss/variables.scss";

.content_card__wrapper {
    color: $black;
}

.content_card_info__wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
    max-width: 90vw;
    word-wrap: break-word;

    span {
        font-weight: 700;
        padding-left: 10px;
    }
}

.description__wrapper {
    margin-bottom: 30px;
    max-width: 600px;
    overflow-y: scroll;
    max-height: 400px;
    padding: 10px;
    background-color: $sky_white;
    border-radius: 12px;
}

