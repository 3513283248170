@import "../../../assets/scss/variables";

.select__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;

    label {
        font-size: $fs_sm;
        font-weight: 200;
    }
}