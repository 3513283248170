@import "../../../assets/scss/variables.scss";

.logo {
max-width: 80px;
color: $white;
padding-top: 8px;
}

.nav__container {
    background-color: $black;
    display: flex;
    justify-content: center;
    height: 70px;
}

.nav-list {
    display: flex;
    gap: 20px;
    font-weight: 300;
}

.nav__wrapper {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link {
    font-size: $fs_md;
    color: $white;
    border-radius: 20px;
    padding: 5px 8px;
    text-decoration: none;
    transition-duration: 0.3s;
}

.link_active {
    background-color: $white;
    border-radius: 20px;
    color: $black;
    padding: 5px 8px;
    text-decoration: none;
}

.link:hover {
    transition-duration: 0.3s;
    background-color: $white;
    border-radius: 20px;
    color: $black;
}