@import "../../assets/scss/variables.scss";

.section__body {
    padding: 10px 20px 50px 20px;
    min-height: 100vh;
    background-color: $sky_white;
    display: flex;
    justify-content: center;
}

.static__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    // max-width: 600px;
    min-width: 375px;
    color: $black;
    border-bottom: 1px solid $sky_white_lighter;

    h1 {
        font-weight: 700;
        font-size: $fs_lg;
    }

    h2, h3, h4  {
        font-weight: 700;
        font-size: $fs_md;
    }

    h5, h6 {
        font-weight: 500;
        font-size: $fs_md;
    }

    p {
        font-weight: 300;
        font-size: $fs_md;
        margin-bottom: 10px;
    }

    ul {
        list-style-type: disc;
        padding: 0px 20px;
    }

    ol {
        list-style-type: decimal;
        padding: 0px 20px;
    }
}

.faqs {
    p:not(:last-child) {
        border-bottom: 1px solid $sky_white_lighter;
    }
}

.privacy, .terms, .faqs {
    h1, h2, h3, h4, h5, h6 {
        padding: 0px 20px;
    }

    p {
        padding: 0px 20px 20px 20px;
    }
}