@import "../../../assets/scss/variables";

.input__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
        font-size: $fs_sm;
        font-weight: 200;
    }
    
    input {
        width: 300px;
        border-radius: 10px;
        height: 35px;
        border:none;
        border: 1px solid $black;
        padding-left: 10px;
        font-weight: 200;
    }

    input:disabled {
        border: 1px solid $light_grey;
        background-color: $light_grey_transparent;
        color: $black_light;
        cursor: not-allowed;
    }

    textarea {
        width: 300px;
        border-radius: 10px;
        border: 1px solid $black;
        padding-left: 10px;
        font-weight: 200;
    }
}

.error__message {
    font-size: $fs_sm;
    font-weight: 400;
    color: $red_base;
    width: 300px;
    padding-right: 10px;
    padding-left: 10px;
}